<template>
  <div class="body">
    <article id="pageTitle" class="jobDetail">
      <header class="hd">
        <h2>{{post.title.rendered}}</h2>
        <p class="meta">
          <template v-for="(meta, mIndex) in post.metadata">
            <span :key="mIndex" v-if="meta && mIndex < 3">{{meta}}</span>
          </template>
        </p>
        <p class="salary" v-if="post.metadata[3]">{{post.metadata[3]}}</p>
      </header>
      <section class="bd" v-html="post.content.rendered"></section>
    </article>
  </div>
</template>

<script>
export default {
  name: 'JobDetail',
  data() {
    return {
      post: {
        title: {},
        content: {},
        metadata: [],
      }
    };
  },
  mounted() {
    const { id } = this.$route.params;

    this.axios({
      url: `posts/${id}`
    }).then((data) => {
      this.post = data;
    });
  }
};
</script>

<style scoped lang="scss">
.body {
  padding: 120px 50px;
  background-color: #f8f8f8;
}

.jobDetail {
  margin: 0 auto;
  max-width: 960px;
  color: #333131;

  .hd {
    padding-left: 20px;
    font-size: 30px;
    line-height: 2;
    border-left: 5px solid #e83e0b;

    h2 {
      font-size: 65px;
      font-weight: bold;
      line-height: 1;
    }

    .meta {
      margin: 4px 0 20px;
      span + span {
        &::before {
          display: inline-block;
          vertical-align: top;
          margin: 16px 28px 0;
          width: 1px;
          height: 28px;
          content: "";
          background-color: #000;
        }
      }
    }

    .salary {
      font-size: 60px;
      line-height: 1;
    }
  }

  .bd {
    padding: 56px 28px;
    font-size: 19px;
    line-height: 1.52631;

    /deep/ p {
      margin: 1.5em 0;
    }

    /deep/ img {
      display: block;
      margin: 1.5em 0;
      width: 100%;
      height: auto;
    }
  }
}



@media only screen and (max-width: 500px) {
  .body {
    padding: 50px 24px;
    background-color: #fff;
  }

  .jobDetail {
    .hd {
      padding-left: 10px;
      font-size: 16px;
      border-left: 3px solid #e83e0b;

      h2 {
        font-size: 32px;
      }

      .meta {
        margin: 2px 0 10px;
        span + span {
          &::before {
            margin: 9px 14px 0;
            width: 1px;
            height: 14px;
          }
        }
      }

      .salary {
        font-size: 30px;
        line-height: 1;
      }
    }

    .bd {
      padding: 28px 14px;
      font-size: 12px;
    }
  }

}
</style>
